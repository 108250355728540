<template>
  <footer class="footer">
    <div class="container">
      <router-link class="logo-font" exact to="/xman" tabindex="-1"> home </router-link>
      <span class="appVersion"> {{ xmanMode }}_{{ environment }}_{{ version }} </span>
    </div>
  </footer>
</template>

<script>
import xcomputed from '../js/xcomputed'
export default {
  name: 'RwvFooter',
  computed: {
    version() {
      return window.xmanConfig.version
    },
    environment: xcomputed.environment,
    xmanMode: xcomputed.xmanMode
  }
}
</script>

<style>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 20px;
  line-height: 20px;
  background-color: #f5f5f5;
}
.footer .appVersion {
  color: lightblue;
  position: absolute;
  bottom: 0px;
  right: 10px;
}
</style>
