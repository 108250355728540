// import '@babel/polyfill'
import axios from 'axios'
import Vue from 'vue'
import jquery from 'jquery'
import tabbable from 'jquery.tabbable'
import VueCookies from 'vue-cookies'
import Papa from 'papaparse'
import lodash from 'lodash'
import fileSaver from 'file-saver'
import moment from 'moment'
import VueMoment from 'vue-moment'
import 'gijgo'
import Vue2Filters from 'vue2-filters'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Inview from 'vueinview'
import PortalVue from 'portal-vue'
import CountryFlag from 'vue-country-flag'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'

Inview.offset({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
})
Inview.threshold(0.1)

Vue.config.devtools = true
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('CountryFlag', CountryFlag)
Vue.use(PortalVue)
Vue.use(Vue2Filters)
Vue.use(VueCookies)
Vue.use(VueMoment)
Vue.use(router)
Vue.use(Inview)
Vue.use(tabbable)
Vue.use(VueDOMPurifyHTML, {
  default: {
    ALLOWED_TAGS: ['mark', 'b', 'p', 'span', 'div', 'em', 'i', 'strong']
  }
})
const { xmanMode } = window.xmanConfig
const { host } = window.location
// enable sockets for live mode only
if (xmanMode === 'live') {
  const socket = io(host, {
    transports: ['websocket']
  })
  Vue.use(VueSocketIOExt, socket)
}
Object.defineProperty(Vue.prototype, '_', { value: _ })
window.$ = jquery
window.jquery = jquery
window.jQuery = jquery
window.moment = moment
window._ = lodash
window.Papa = Papa
window.axios = axios
window.fileSaver = fileSaver
window.Vue = Vue
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
