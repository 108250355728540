<template>
  <div class="polarity-cat-score">
    <b-form-checkbox
      v-model="categoryScoreCheckboxModel"
      class="catScoreCheckbox mx-2"
      @change="toggleAssociationCheckbox()"
    >
    </b-form-checkbox>
    <span v-if="!isOriginal" class="original-score">
      <span v-b-tooltip.hover :title="`originally was ${_.isNil(originalScore) ? 'NA' : originalScore}`">*</span></span
    >
    <span class="polarity-score" :class="[{ dirty: isDirty() }, scoreClass, { notOriginal: !isOriginal }]">{{
      _.get(entity, lensScorePath, 'NA') | round
    }}</span>
    <span class="score-selector inline-flex">
      <b-form-select
        v-model="localSelected"
        class="level-selector"
        :class="[{ dirty: isDirty() }, selectedScoreClass()]"
        :options="formPolarityLevels"
      ></b-form-select>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xentities from '../../js/xentities'
import xentitiesComputed from '../../js/xentitiesComputed'
import xconfig from '../../js/xconfig'

const { pipelines } = xconfig

export default {
  name: 'PolarityCatScore',
  filters: {
    round: function (value) {
      if (typeof value !== 'number') return value
      return Math.round(value)
    }
  },
  props: {
    entity: {
      type: Object,
      default: null
    },
    articleId: {
      type: String,
      default: null
    },
    originalScore: {
      type: Number,
      default: undefined
    },
    lens: {
      type: String,
      default: null
    },
    category: {
      type: String,
      default: null
    },
    currentPipeline: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      categoryScoreCheckboxModel: true
    }
  },
  computed: {
    ...xentitiesComputed,
    ...mapGetters([
      'articles',
      'count',
      'modalArticle',
      'articleFilters',
      'collectionType',
      'collectionName',
      'expandedScores',
      'polarityChanges'
    ]),
    lensKey() {
      return this.currentPipeline === 'v2' ? `categories.${_.toUpper(this.lens)}scores` : `scores.${this.lens}`
    },
    lensScorePath() {
      return `${this.lensKey}.${this.category}`
    },
    polarityChangePath() {
      return `${this.articleId}.${this.currentPipeline}.${this.entity.tvlId}.${this.lensScorePath}`
    },
    ...mapState({
      catValue(state) {
        return _.get(state, `polarityChanges.${this.polarityChangePath}`)
      }
    }),
    initialScore() {
      return _.round(_.get(this.entity, this.lensScorePath, 0))
    },
    prevFormPolarityLevels() {
      const levels = _.map(xconfig.polarityScoreLevels, (polarityLevel) => {
        const initial = polarityLevel.level === this.initialScore ? ' *' : ''
        const text = `${polarityLevel.level.toString()}${initial}`
        return { value: polarityLevel.level, text }
      })
      return levels
    },
    formPolarityLevels() {
      const formattedScoreList = Array.from({ length: 21 }, (_, i) => i * 5)
      const isPresent = formattedScoreList.includes(this.initialScore)
      if (!isPresent) {
        formattedScoreList.push(this.initialScore)
        formattedScoreList.sort((a, b) => a - b)
      }
      const levels = _.map(formattedScoreList, (value) => {
        const initial = value === this.initialScore ? ' *' : ''
        const text = `${value.toString()}${initial}`
        return { value, text }
      })
      return levels
    },
    scoreClass() {
      return this.getScorePolarityClass(_.get(this.entity, this.lensScorePath))
    },
    pipelines() {
      return pipelines
    },
    isOriginal() {
      const currentScore = _.get(this.entity, this.lensScorePath)
      if (this.originalScore !== currentScore) {
        return false
      }
      return true
    },
    categoryScoreModel() {
      return _.get(this.entity, this.lensScorePath, 0)
    },
    localSelected: {
      get: function () {
        const fromSelection = this.catValue
        const initial = this.initialScore
        const fromSelectionLevel = _.round(fromSelection)
        const val = _.isNil(fromSelection) ? initial : fromSelectionLevel
        return val
      },
      set: function (newValue) {
        if (this.categoryScoreCheckboxModel !== newValue > 0) {
          this.categoryScoreCheckboxModel = newValue > 0
        }
        this.polarityChange(newValue)
      }
    }
  },
  mounted() {
    this.localSelected = this.initialScore
  },
  methods: {
    ...xentities,
    isDirty() {
      return this.localSelected !== this.initialScore
    },
    selectedScoreClass() {
      if (this.localSelected === this.initialScore) return 'no'
      return this.getScorePolarityClass(this.localSelected)
    },
    polarityChange(score) {
      const polarityLevel = score !== this.initialScore ? score : 'initial'
      const polarityChangePath = `${this.articleId}.${this.currentPipeline}.${this.entity.tvlId}.${this.lensScorePath}`
      if (score === this.initialScore) {
        this.$store.dispatch('clearPolarityChange', { polarityChangePath })
      } else {
        const newScore = polarityLevel
        this.$store.dispatch('addPolarityChange', { polarityChangePath, change: newScore })
      }
    },
    toggleAssociationCheckbox() {
      const initialScore = this.initialScore ? this.initialScore : 50
      const score = this.categoryScoreCheckboxModel ? initialScore : 0
      if (this.localSelected !== score) {
        this.localSelected = score
      }
    }
  }
}
</script>
