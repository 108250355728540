<template>
  <div class="form-row">
    <div class="col-auto m-1">
      Sort:
      <select id="reverse-sort" v-model="localSort" name="reverse-sort" class="form-control-sm" tabindex="-1">
        <option value="1">Oldest</option>
        <option value="-1">Newest</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'

export default {
  name: 'ControlSortDirection',
  computed: {
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      sortDirection: (state) => state.articleFilters.sort.date.direction
    }),
    localSort: {
      get: function () {
        return this.sortDirection === 'asc' ? 1 : -1
      },
      set: function (newValue) {
        this.toggleSort(newValue)
      }
    }
  },
  methods: {
    async toggleSort(direction) {
      const sortDirectionValue = _.toNumber(direction) > 0 ? 'asc' : 'desc'
      const sort = _.clone(this.articleFilters.sort)
      _.set(sort, 'date.direction', sortDirectionValue)
      this.$store.dispatch('setArticleFilter', { sort })
      xlibs.applyFilters.call(this)
    }
  }
}
</script>
