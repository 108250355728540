import xlibs from './xlibs'

const xcomputed = {
  isAdmin() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 21) return true
    return false
  },
  isEntityCurator() {
    if (xcomputed.isAdmin()) return true
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 22) return true
    return false
  },
  isSentimentCurator() {
    if (xcomputed.isAdmin()) return true
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 24) return true
    return false
  },
  isSpotlightAnalyst() {
    if (xcomputed.isAdmin()) return true
    const user = JSON.parse(localStorage.getItem('user'))
    if (_.isEmpty(user)) {
      xlibs.showErrorAlert('missing user information')
      xlibs.logout()
    }
    if (_.get(user, 'roleId') === 25) return true
    return false
  },
  isLive() {
    return xcomputed.xmanMode() === 'live'
  },
  isStatic() {
    return xcomputed.xmanMode() === 'static'
  },
  xmanMode() {
    return window.xmanConfig.xmanMode
  },
  featureSwitches() {
    return _.get(window, 'xmanConfig.featureSwitches', {})
  },
  featureSwitchesOn() {
    const onSwitches = _.keys(_.pickBy(xcomputed.featureSwitches()))
    return onSwitches
  },
  curatingFrom() {
    return _.camelCase(this.$options.name)
  },
  formattedDate() {
    return (dateMs, localize = false) => {
      if (!dateMs) return ''
      return localize ? moment(dateMs).toString() : moment.utc(dateMs).format('ddd, MMM D YYYY, HH:mm:ss').toString()
    }
  },
  currentCount() {
    this.count.currentCount = _.get(self, 'articles.length', 0)
    return this.count.currentCount
  },
  showLoadMore() {
    const self = this
    return self.count.totalCount > self.count.currentCount
  },
  environment() {
    return window.xmanConfig.env
  }
}

export default xcomputed
