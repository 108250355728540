<template>
  <div class="spotlight-suppressed-page spotlights-page main-template">
    <div class="position-absolute statsBox">
      <curationStats ref="curationStats" />
      <subNav />
    </div>
    <div class="container-fluid form-controls">
      <controlLensFilter :spotlight-mode="true" />
      <controlPipelineFilter />
      <controlUserFilter :spotlight-mode="true" />
      <div class="form-row">
        <controlCompanyFilter />
      </div>
      <controlDateFilter />
    </div>
    <div class="container-fluid count">
      showing {{ count.currentCount }} of {{ count.totalCount }}
      {{ count.totalCount | pluralize('spotlight') }}
    </div>
    <div id="search-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
    <div v-if="spotlights" class="container-fluid page spotlights">
      <div class="row spotlight-header sticky-top">
        <div class="col col-sm-1 curationSection">
          <div class="row curationRow">
            <sortDateTableHeader container-class="col-sm-12 curationDateCol" />
          </div>
        </div>
        <div class="col col-sm-6 spotlightDetailsSection">
          <div class="row spotlightsRow">
            <localSortTableHeader container-class="titleCol float-left my-col" field-type="headline" />
            <localSortTableHeader container-class="sourceCol float-right col-3 text-right" field-type="source" />
          </div>
        </div>
        <div class="col col-sm-5 entitiesSection"></div>
      </div>
      <div
        v-for="(spotlight, spotlightIndex) in spotlights"
        :id="'spotlight-' + spotlight._id"
        :key="spotlight._id"
        v-inview:class="{ onpage: true }"
        v-inview:class.leave="{ onpage: false }"
        class="row spotlight"
        :spotlight="spotlight"
        :class="{ disabled: !_.isEmpty(spotlight.curationDisabled) }"
      >
        <curation-disabled-note :curation-disabled="spotlight.curationDisabled" @resetPage="resetPage" />
        <div class="col col-sm-1 curationSection">
          <div class="row">
            <div class="col col-sm-12 curationDateCol">
              <curationDateInfo :article="spotlight" :spotlight-id="spotlight.spotlightId" />
            </div>
          </div>
        </div>

        <div class="col col-sm-6 spotlightDetailsSection">
          <div class="row">
            <div class="col spotlight-body">
              <div class="titleCol">
                <a
                  data-toggle="modal"
                  data-target="#articleModal"
                  tabindex="-1"
                  href="#"
                  @click="openArticle(_.get(spotlight, 'article'))"
                  >{{
                    _.get(spotlight, 'article.title') || _.get(spotlight, 'article.headline_en', 'article not found')
                  }}</a
                >
              </div>
              <hr />
              <span v-dompurify-html="highlightSummary(spotlight.article, spotlight.displayArticle)" class="summaryCol">
              </span>
            </div>
          </div>
        </div>
        <div class="col col-sm-5 entitiesSection">
          <spotlightEntity
            :article-index="spotlightIndex"
            :curating-from="curatingFrom"
            :spotlight-lens="articleFilters.lens"
            :spotlight="spotlight"
          />
        </div>
      </div>
      <div v-if="_.get(spotlights, 'length') === 0" id="noSpotlights" class="row">
        <div class="col screen-centered"><b>No Spotlights Found</b></div>
      </div>
      <div v-if="showLoadMore" id="moreArticles" class="row">
        <div class="col screen-centered">
          <button
            v-inview:class="{ onpage: true }"
            v-inview:class.leave="{ onpage: false }"
            type="button"
            class="btn-sm btn-primary"
            @click="loadMoreSpotlights"
          >
            load more ...
          </button>
        </div>
      </div>
    </div>
    <articleModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import articleModal from '../components/articleModal'
import curationStats from '../components/curationStats'
import controlCompanyFilter from '../components/formControls/controlCompanyFilter'
import controlDateFilter from '../components/formControls/controlDateFilter'
import controlLensFilter from '../components/formControls/controlLensFilter'
import controlPipelineFilter from '../components/formControls/controlPipelineFilter'
import controlUserFilter from '../components/formControls/controlUserFilter'
import curationDisabledNote from '../components/curationDisabledNote'
import spotlightEntity from '../components/entites/spotlightEntity'
import sortDateTableHeader from '../components/sortDateTableHeader'
import localSortTableHeader from '../components/localSortTableHeader'
import curationDateInfo from '../components/curationDateInfo'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'
import xentitiesComputed from '../js/xentitiesComputed'
// import spotlightsLib from '../js/spotlightsLib'
import subNav from '../components/subNav'

export default {
  name: 'SpotlightsSuppressed',
  components: {
    articleModal,
    curationStats,
    controlCompanyFilter,
    controlDateFilter,
    controlLensFilter,
    controlPipelineFilter,
    controlUserFilter,
    curationDateInfo,
    curationDisabledNote,
    spotlightEntity,
    sortDateTableHeader,
    localSortTableHeader,
    subNav
  },
  filters: {},
  props: [],

  data() {
    return {
      currentUser: {}
    }
  },
  computed: {
    ...xcomputed,
    ...xentitiesComputed,
    ...mapGetters(['spotlights', 'modalArticle', 'count', 'articleFilters', 'collectionType', 'collectionName'])
  },
  mounted() {
    this.currentUser = xlibs.getUser()
  },
  created() {
    this.$store.dispatch('resetArticleFilters', 'spotlightsSuppressed')
    this.setCuratingFrom(this.curatingFrom)
    this.resetPage()
  },
  destroyed() {},
  methods: {
    ...xlibs,
    //    ...spotlightsLib,

    async resetPage() {
      // dropdown defaults
      await this.clearStores()
      // this.sort = 1
      await this.applyFilters()
      // const initialFocus = $(_.get(this, '$refs.initialBtn[0].$el', {}))
      // initialFocus.focus()
    },
    highlightSummary(article, displayArticle) {
      return xlibs.highlightSummary(article, displayArticle, true)
    },
    async updateSpotlights(spotlights, totalCount) {
      this.$store.dispatch('setSpotlights', spotlights)
      this.$store.dispatch('updateCount', totalCount, 'spotlights')
    },
    loadMoreSpotlights() {
      this.$store.dispatch('setArticleFilter', { page: this.articleFilters.page + 1 })
      xlibs.toPrevItem()
      xlibs.applyFilters.call(this)
    }
  },
  sockets: {
    xmanCurationSocket: xlibs.xmanCurationSocket
  }
}
</script>
<style></style>
