<template>
  <div class="form-row">
    <div class="col-auto m-1">
      Lens:
      <select
        id="lens-filter"
        :value="lens"
        name="lens-filter"
        class="form-control-sm"
        tabindex="-1"
        @input="updateLens"
      >
        <option v-for="option in formOptions" :key="option.key" :value="option.key">
          {{ option.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xconfig from '../../js/xconfig'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'

export default {
  name: 'ControlLensFilter',
  props: {
    spotlightMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    formOptions() {
      let options = this.spotlightMode ? _.filter(xconfig.lenses, 'spotlightsEnabled') : xconfig.lenses
      return options.concat([{ key: 'all', name: 'All' }])
    },
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      lens: (state) => state.articleFilters.lens
    })
  },
  methods: {
    async updateLens(e) {
      this.$store.dispatch('setArticleFilter', { lens: e.target.value })
      xlibs.applyFilters.apply(this)
    }
  }
}
</script>
