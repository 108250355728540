<template>
  <a
    ref="approveBtn"
    v-inview:class="{ onpage: true }"
    v-inview:class.leave="{ onpage: false }"
    class="approve"
    :class="{
      disabled:
        article.approveDisabled || !_.isEmpty(article.curationDisabled) || featureSwitches.disableArticleCuration
    }"
    :tabindex="article.approveDisabled || !_.isEmpty(article.curationDisabled) ? -1 : 0"
    title="Approve Selected"
    href="#"
    @click.prevent="approveArticle(article._id)"
    ><i class="fa fa-check-circle"
  /></a>
</template>

<script>
import { mapGetters } from 'vuex'
import xcomputed from '../../js/xcomputed'
import xlibs from '../../js/xlibs'
const { approveArticle, prepareApprovalModel } = xlibs

export default {
  props: {
    article: {
      type: Object,
      default: null
    },
    articleIndex: {
      type: Number,
      default: null
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    featureSwitches: xcomputed.featureSwitches,
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName'])
  },
  methods: {
    approveArticle,
    prepareApprovalModel
  }
}
</script>
